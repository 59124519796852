@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./dist/output.css);

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* @layer base {
	@font-face {
		font-family: 'Doom';
		src: url('./fonts/Doom2016Text-GOlBq.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}
    @font-face {
		font-family: 'MedievalTimes';
		src: url('./fonts/MedievalTimes-AL7l6.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}
} */

.App {
	text-align: center;
	background-color: #282c34;
	overflow-x: hidden;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Dark Mode */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #3d4044;
}

input:focus + .slider {
	box-shadow: 0 0 1px #3d4044;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

input:checked + .slider:before {
	transform: translateX(26px);
	background-image: url(/src/assets/moon.svg);
	background-size: contain;
	content: '';
	background-color: #3d4044;
}

input:not(:checked) + .slider:before {
	transform: translateX(0);
	background-image: url(/src/assets/sun.svg);
	background-size: contain;
	content: '';
}

.slider.round {
	border-radius: 34px;
	width: 60px;
}

.slider.round:before {
	border-radius: 50%;
}

.font-medieval {
	font-family: 'MedievalTimes';
}

.chart {
	color:darkgoldenrod
}
